import { ContentType } from "./ContentType";
import ConcordFloralCover from "../assets/ConcordFloralCover.webp";
import ConcordFloral1 from "../assets/ConcordFloral1.webp";
import ConcordFloral2 from "../assets/ConcordFloral2.webp";
import ConcordFloral3 from "../assets/ConcordFloral3.webp";
import ConcordFloral4 from "../assets/ConcordFloral4.webp";
import ConcordFloral5 from "../assets/ConcordFloral5.webp";
import Upstart2018Cover from "../assets/Upstart2018Cover.webp";
import Upstart20181 from "../assets/Upstart20181.webp";
import Upstart20182 from "../assets/Upstart20182.webp";
import Upstart20183 from "../assets/Upstart20183.webp";
import Upstart20184 from "../assets/Upstart20184.webp";
import TomorrowLoveCover from "../assets/TomorrowLoveCover.webp";
import TomorrowLove1 from "../assets/TomorrowLove1.webp";
import TomorrowLove2 from "../assets/TomorrowLove2.webp";
import TomorrowLove3 from "../assets/TomorrowLove3.webp";
import TomorrowLove4 from "../assets/TomorrowLove4.webp";
import TomorrowLove5 from "../assets/TomorrowLove5.webp";
import TomorrowLove6 from "../assets/TomorrowLove6.webp";
import WelcometotheTreeMuseumCover from "../assets/WelcometotheTreeMuseumCover.webp";
import WelcometotheTreeMuseum1 from "../assets/WelcometotheTreeMuseum1.webp";
import WelcometotheTreeMuseum2 from "../assets/WelcometotheTreeMuseum2.webp";
import WelcometotheTreeMuseum3 from "../assets/WelcometotheTreeMuseum3.webp";
import WelcometotheTreeMuseum4 from "../assets/WelcometotheTreeMuseum4.webp";
import WelcometotheTreeMuseum5 from "../assets/WelcometotheTreeMuseum5.webp";
import WelcometotheTreeMuseum6 from "../assets/WelcometotheTreeMuseum6.webp";
import allofthethingsweretakingCover from "../assets/allofthethingswe'retakingCover.webp";
import SingleandSexyCover from "../assets/SingleandSexyCover.webp";
import SingleandSexy1 from "../assets/SingleandSexy1.webp";
import TheSeagullCover from "../assets/TheSeagullCover.webp";
import TheSeagull1 from "../assets/TheSeagull1.webp";
import TheSeagull2 from "../assets/TheSeagull2.webp";
import TheSeagull3 from "../assets/TheSeagull3.webp";
import TheSeagull4 from "../assets/TheSeagull4.webp";
import TheSeagull5 from "../assets/TheSeagull5.webp";

import GenericWaterlooCover from "../assets/GenericWaterlooCover.webp";
import TravelingtheWorldCover from "../assets/TravelingtheWorldCover.webp";
import TravelingtheWorld1 from "../assets/TravelingtheWorld1.webp";
import TravelingtheWorld2 from "../assets/TravelingtheWorld2.webp";
import TravelingtheWorld3 from "../assets/TravelingtheWorld3.webp";
import SavetheDateCover from "../assets/SavetheDateCover.webp";
import StD1 from "../assets/StD1.webp";
import StD2 from "../assets/StD2.webp";
import StD3 from "../assets/StD3.webp";
import StD4 from "../assets/StD4.webp";
import StD5 from "../assets/StD5.webp";
import StD6 from "../assets/StD6.webp";
import StD7 from "../assets/StD7.webp";
import StD8 from "../assets/StD8.webp";
import StD9 from "../assets/StD9.webp";
import StD10 from "../assets/StD10.webp";
import HumberPastryCupCover from "../assets/HumberPastryCupCover.webp";
import BonfireOriginalsCover from "../assets/BonfireOriginalsCover.webp";
import CBCCover from "../assets/CBCCover.webp";
import HawksSportsLogo from "../assets/HawksSportsLogo.webp";
import OCAAWomensVolleyballHumberVsCambrian from "../assets/OCAAWomensVolleyballHumberVsCambrian.webp";
import OCAAMensVolleyballHumberVsCambrian from "../assets/OCAAMensVolleyballHumberVsCambrian.webp";
import OCAAWomensVolleyballHumberVsSheridan from "../assets/OCAAWomensVolleyballHumberVsSheridan.webp";
import HumberEsportsSmash from "../assets/HumberEsportsSmash.webp";
import Fanshawe1 from "../assets/Fanshawe1.webp";
import Fanshawe2 from "../assets/Fanshawe2.webp";
import OCAAMensBasketballHumberCollegeVsCanadoreCollege from "../assets/OCAAMensBasketballHumberCollegeVsCanadoreCollege.webp";
import OCAAWomensVolleyballHumberVsRedeemer from "../assets/OCAAWomensVolleyballHumberVsRedeemer.webp";
import OCAAMensVolleyballHumberVsRedeemer from "../assets/OCAAMensVolleyballHumberVsRedeemer.webp";
import CamosunVsFanshawe from "../assets/CamosunVsFanshawe.webp";
import OHLHockey from "../assets/OHLHockey.webp";
import HumberMusicProducerinResidence from "../assets/HumberMusicProducerinResidence.webp";
import BriercrestVsProvidence from "../assets/BriercrestVsProvidence.webp";
import VIUVsSaintAnne from "../assets/VIUVsSaintAnne.webp";
import Sherbrooke from "../assets/Sherbrooke.webp";
import VIUVsBriercrest from "../assets/VIUVsBriercrest.webp";
import BSICover from "../assets/BSICover.webp";
import NataPRCover from "../assets/NataPRCover.webp";
import accessCANCover from "../assets/accessCANCover.webp";
import CSACover from "../assets/CSACover.webp";

export const content: ContentType[] = [
  {
    name: "Concord Floral",
    titleTop: "Concord",
    titleBottom: "Floral",
    fontSizeTop: "5vw",
    fontSizeBottom: "7.43vw",
    date: new Date(2017, 11),
    location: "Waterloo, ON",
    role: "Technical Crew and Dramaturg",
    organization: "University of Waterloo",
    credits:
      "Directed by Vikki Anderson, Written by Jordan Tannahill and Photographs by William Innes",
    description: [
      "Concord Floral delves into themes of alienation, sexuality, dislocation, loneliness, and exclusion, all within the context of a teenage world. This production offers young audiences a unique opportunity to see their own experiences reflected through the perspectives of a talented and diverse cast of young performers.",
      "With sensitivity and candor, the play navigates the complexities of growing up, shedding light on the pervasive anxiety and fear that young people often face in today's world. It weaves a dark narrative of revenge into the fabric of disaffected suburban teen life, delivering its message with intelligence, humor, and a captivating theatrical spectacle.",
    ],
    responsibilities: [
      "Researched and edited content for the show website and other social media pages.",
      "Managed the theatre department’s Instagram account for the term and organized photoshoots for the production.",
      "Created an audience outreach plan and designed other promotional materials to increase attendance.",
    ],
    url: "https://uwaterloo.ca/communication-arts/performances/dramaturgy-and-archives/concord-floral-dramaturgy-hub",
    genre: "Theatre",
    category: "Theatre",
    videos: ["https://www.youtube.com/embed/UJmXAuBmSXU?si=3URJj5ADYgVaFkL-"],
    photos: [
      ConcordFloralCover,
      ConcordFloral1,
      ConcordFloral2,
      ConcordFloral3,
      ConcordFloral4,
      ConcordFloral5,
    ],
  },
  {
    name: "Upstart 2018",
    titleTop: "Upstart",
    titleBottom: "2018",
    fontSizeTop: "5vw",
    fontSizeBottom: "7.97vw",
    date: new Date(2018, 3),
    location: "Waterloo, ON",
    role: "Technical Crew",
    genre: "Theatre",
    organization: "University of Waterloo",
    credits:
      "Hamlet Reworked adapted and performed by Gareth Potter, The Game of String directed by Brooke Barnes and written by alumna Jenn Addesso, and Hopscotch directed by Rebecca Reid and written by Joanna Cleary, Photographs by William Innes",
    description: [
      "UpStart, a bi-annual one-act play festival, showcased three compelling theatrical works crafted and performed by University of Waterloo students and alum.",
      "Hamlet Reworked - In the unconventional setting of the Modern Languages building's lobby, Gareth Potter reimagined Shakespeare's Hamlet in 'Hamlet Reworked.' Breaking barriers, Potter's solo performance explored themes of mortality and grief, challenging the audience's perceptions of this classic text. Divided into three segments, the play pushed the boundaries of live theatre and culminated in a mesmerizing finale onstage.",
      "The Game of String - A thought-provoking exploration of our digital selves, dissecting the competition inherent in online personas and questioning the authenticity of the images we leave behind after death.",
      "Hopscotch - Delve into the afterlife, where Eva and her friend Chloe grapple with preserving their shared memories. Through raw honesty and the symbolism of hopscotch, the play delved into the depths of true remembrance.",
      "These diverse plays, each brimming with innovative perspectives, wove a tapestry of reflection, connection, and loss. The 2018 UpStart Festival left an indelible mark, offering an unforgettable theatrical experience that challenged conventions and inspired audiences.",
    ],
    responsibilities: [
      "Worked on 2 different shows with the technical director and set designer to build and paint the set and props.",
      "Hung and focused lighting fixtures.",
      "Assisted the head of Wardrobe in labeling, sewing and repairing costume pieces.",
    ],
    url: "https://uwaterloo.ca/communication-arts/upstart-2018",
    category: "Theatre",
    videos: [
      "https://www.youtube.com/embed/nwC4PmEtV-Y?si=JvNcdYEiOyRVrXeB",
      "https://www.youtube.com/embed/sotWaxFz320?si=9JT09Cb12ydWbUbX",
      "https://www.youtube.com/embed/K5HgPVAZjRA?si=Dzc5x-oovOW1YJm3",
    ],
    photos: [Upstart2018Cover, Upstart20181, Upstart20182, Upstart20183],
  },
  {
    name: "Tomorrow Love",
    titleTop: "Tomorrow",
    titleBottom: "Love",
    fontSizeTop: "5vw",
    fontSizeBottom: "8.88vw",
    date: new Date(2018, 11),
    location: "Waterloo, ON",
    role: "Assistant Stage Manager",
    genre: "Theatre",
    organization: "University of Waterloo",
    credits:
      "Directed by Andrea Donaldson, written by Rosamund Small and photgraphs by William Innes",
    description: [
      "Embark on a futuristic love journey with TomorrowLove, where cutting-edge technology intertwines with human emotions. This innovative production explored the impact of groundbreaking inventions on relationships. Featuring seven interconnected stories set in the near future, TomorrowLove presents a thought-provoking blend of love, intimacy, and transformation. The play created a surreal world using paper and light, offering a unique perspective on relationships unaffected by technology.",
      "Notably, TomorrowLove also pioneered ASL interpreted shows, promoting inclusivity and ensuring a broader audience can embrace its futuristic vision",
    ],
    responsibilities: [],
    url: "https://uwaterloo.ca/communication-arts/performances/dramaturgy-and-archives/tomorrowlove-hub",
    category: "Theatre",
    videos: ["https://www.youtube.com/embed/IMTDxU1SvYo?si=-hkRIzBY2sI16Qxt"],
    photos: [
      TomorrowLoveCover,
      TomorrowLove1,
      TomorrowLove2,
      TomorrowLove3,
      TomorrowLove4,
      TomorrowLove5,
      TomorrowLove6,
    ],
  },
  {
    name: "",
    titleTop: "Backstage",
    titleBottom: "Technical Crew",
    fontSizeTop: "6.52vw",
    fontSizeBottom: "4.5vw",
    date: new Date(2018, 9),
    location: "Waterloo, ON",
    role: "Backstage Technical Crew",
    genre: "Dance",
    organization: "University of Waterloo",
    credits: "",
    description: [],
    responsibilities: [
      "Set up the stage for rehearsals and final performances by hanging and focusing appropriate lighting fixtures, spiking the stage and setting up props",
      "Operated sound, lighting boards and other equipment during rehearsal and final performances.",
      "Made sure safety protocols were being followed by the performers.",
    ],
    url: "https://uwaterloo.ca/theatres/venue-rental/humanities-theatre",
    category: "Theatre",
    videos: [],
    photos: [GenericWaterlooCover],
  },
  {
    name: "Welcome to the Tree Museum",
    titleTop: "Welcome To The",
    titleBottom: "Tree Museum",
    fontSizeTop: "5vw",
    fontSizeBottom: "5.915vw",
    date: new Date(2019, 3),
    location: "Waterloo, ON",
    role: "Actor - Eight Day",
    genre: "Theatre",
    organization: "University of Waterloo",
    credits:
      "Directed and devised by Andrew Houston with Associate Director Brooke Barnes, written by Robert Plowman and photographs by William Innes",
    description: [
      "Step into the world of 'Welcome to the Tree Museum,' a captivating multimedia performance that delved into an eerily familiar world hurtling towards ecological disaster. The story revolves around the Brash siblings - Hazy, Queenie, Thunder, and Toot - who, having grown up believing they would be the saviors of our planet, are confronted by the impending death of their beloved childhood woodland.",
      "The narrative takes a poignant turn when the siblings reunite due to a wedding and illness, forcing them to confront the severed connection between family and the environment. Developed through an immersive research process by UWaterloo students, the play urges the audience to reconsider humanity's disregard for the environment. It challenges viewers to rekindle their relationship with nature, reawakening the childlike wonder we once had for the outdoors.",
      "Through this compelling narrative, 'Welcome to the Tree Museum' asks a fundamental question: Can we rediscover our love for nature?",
    ],
    responsibilities: [],
    url: "https://uwaterloo.ca/communication-arts/welcome-tree-museum",
    category: "Theatre",
    videos: ["https://www.youtube.com/embed/KjXxQsrzxeo?si=ZdtrhXFUXCmUyZfJ"],
    photos: [
      WelcometotheTreeMuseumCover,
      WelcometotheTreeMuseum1,
      WelcometotheTreeMuseum2,
      WelcometotheTreeMuseum3,
      WelcometotheTreeMuseum4,
      WelcometotheTreeMuseum5,
      WelcometotheTreeMuseum6,
    ],
  },
  {
    name: "all of the things we’re taking",
    titleTop: "all of the things",
    titleBottom: "we're taking",
    fontSizeTop: "5vw",
    fontSizeBottom: "6.298vw",
    date: new Date(2019, 5),
    location: "Waterloo, ON",
    role: "Actor - Sonia",
    genre: "Theatre",
    organization: "Playground Festival",
    credits: "Written and Directed by Joanna Cleary",
    description: [
      "Inspired by the song “Perfect Places” (Lorde), three university-age friends lives intersect as they come to realize what adulthood is all about. Sonia, Rae, and Mike struggle to give and receive from each other, and reflect on what it means to love and place faith in other people.",
    ],
    responsibilities: [],
    url: "http://playground.registrytheatre.com/allofthethings.html",
    category: "Theatre",
    videos: [],
    photos: [allofthethingsweretakingCover],
  },
  {
    name: "Single and Sexy",
    titleTop: "Single and",
    titleBottom: "Sexy",
    fontSizeTop: "5vw",
    fontSizeBottom: "11.2vw",
    date: new Date(2019, 8),
    location: "Waterloo, ON",
    role: "Stage Manager",
    genre: "Theatre",
    organization: "University of Waterloo Health Services",
    credits: "Directed by Dan Payne",
    description: [],
    responsibilities: [
      "Organized and scheduled rehearsals and the final performances.",
      "Documented the entire rehearsal process by making daily rehearsal notes.",
      "Maintained steady communication between all the departments (e.g., props, costumes, and sound).",
    ],
    url: "https://uwaterloo.ca/campus-wellness/health-promotion/orientation-play",
    category: "Theatre",
    videos: [],
    photos: [SingleandSexyCover, SingleandSexy1],
  },
  {
    name: "The Seagull",
    titleTop: "The",
    titleBottom: "Seagull",
    fontSizeTop: "10vw",
    fontSizeBottom: "4.738vw",
    date: new Date(2019, 11),
    location: "Waterloo, ON",
    role: "Head of Props and Paint",
    genre: "Theatre",
    organization: "University of Waterloo",
    credits:
      "Directed by with Associate Director Joanna Cleary, written by Anton Chekhov and phtographs by William Innes",
    description: [
      "Navigating adolescence is tough on its own, but imagine dealing with a self-absorbed actress mother dating a wildly successful writer. To complicate matters, this writer effortlessly woos your own girlfriend, leaving you in a whirlwind of emotions. Such is the challenging reality faced by Konstantin, a young artist trying to carve out his identity.",
      "In 'The Seagull,' characters passionately discussed love and art, creating moments of razor-sharp wit. Chekhov labeled it a comedy, prompting director Matt White to mine these small dramas for maximum comic impact.",
      "This Canadian adaptation of the play infused contemporary elements, highlighting our modern-day societal quirks and distractions, yet remaining eerily relevant and mirroring the struggles of over a century ago. Chekhov's exploration of love, acceptance, and survival, alongside the unrealistic expectations people place on one another, remains profoundly accurate. In 'The Seagull,' there are no simple solutions; life persists, and one must endure, facing challenges head-on.",
    ],
    responsibilities: [],
    url: "https://uwaterloo.ca/communication-arts/performances/past-performances/seagull",
    category: "Theatre",
    videos: ["https://www.youtube.com/embed/e9k1hSeE0rI?si=zWrNSN2984-rmfta"],
    photos: [
      TheSeagullCover,
      TheSeagull1,
      TheSeagull2,
      TheSeagull3,
      TheSeagull4,
      TheSeagull5,
    ],
  },
  {
    name: "",
    titleTop: "Costume Shop",
    titleBottom: "Assistant",
    fontSizeTop: "5vw",
    fontSizeBottom: "7.604vw",
    date: new Date(2020, 3),
    location: "Waterloo, ON",
    role: "Costume Shop Assistant (Apprenticeship)",
    genre: "Theatre",
    organization: "University of Waterloo",
    credits: "",
    description: [],
    responsibilities: [
      "Kept a meticulous organization of all the clothing and accessories.",
      "Worked closely with team members to deliver project requirements, develop solutions and meet deadlines.",
      "Assisted with costume fittings, cutting, stitching and alterations with design techniques.",
    ],
    url: "https://uwaterloo.ca/theatres/venue-rental/theatre-arts",
    category: "Theatre",
    videos: [],
    photos: [GenericWaterlooCover],
  },
  {
    name: "Traveling the World: One Story at a Time",
    titleTop: "Traveling the World:",
    titleBottom: "One Story at a Time",
    fontSizeTop: "5vw",
    fontSizeBottom: "5.223vw",
    date: new Date(2022, 3),
    location: "Etobicoke, ON",
    role: "Co-Producer and Director",
    genre: "Documentary",
    organization: "Humber College",
    credits:
      "Produced by Shamanta Haider, directed by Paige Nethercott, camera operated by Kofi Osei, edited by Emily Lepp, lighting operator Ekaterina Andreeva and archival footage courtesy of Hussain Alokaily",
    description: [
      "Meet Ahmad Jabbir, a young Toronto man who decided to pursue social discomfort by talking to 100 strangers, 1 on 1, for an hour each, during the Covid-19 pandemic. Check out our short documentary on his journey!",
      "This video is a school project created by the Humber College Broadcast Television & Videography students.",
    ],
    responsibilities: [
      "Selected for Humber College's Winter 2022 documentary productions out of 20+ other pitches.",
      "Handled all production logistics including budgeting, location scouting, and scheduling shoot dates.",
      "Organized production meetings and delegated tasks to a crew.",
      "Gained a solid understanding of field shoots, such as preparing for optimal sound and lighting setup.",
    ],
    url: "https://www.youtube.com/watch?v=Sd_iAGzIm3c",
    category: "Film/TV",
    videos: [
      "https://www.youtube.com/embed/Sd_iAGzIm3c?si=t1Om4WpAIMdoUmEN",
      "https://www.youtube.com/embed/NSvztx1FDo8?si=P6GPMvR5H6c9PI2j",
      "https://www.youtube.com/embed/Gzb7ES2QMQk?si=PVXTPqnhn3-g7mGL",
    ],
    photos: [
      TravelingtheWorldCover,
      TravelingtheWorld1,
      TravelingtheWorld2,
      TravelingtheWorld3,
    ],
  },
  {
    name: "Save the Date",
    titleTop: "Save the",
    titleBottom: "Date",
    fontSizeTop: "5vw",
    fontSizeBottom: "9.682vw",
    date: new Date(2022, 11),
    location: "Etobicoke, ON",
    role: "Writer and Producer",
    genre: "Series Pilot",
    organization: "Humber College",
    credits:
      "Writen and produced by Shamanta Haider, directed by Shantanu Udasi, camera operated by Kofi Osei, production assistant: Paige Nethercott, co-editor Emily Lepp, and boom operator Shaun Allen",
    description: [
      "A 31-year-old gay man introduces his boyfriend to his traditional South Asian family at his sister’s wedding, where he is forced to deal with family disappointments and his own resentments in order to earn their acceptance.",
    ],
    responsibilities: [
      "Wrote and pitched an original limited series to Humber College faculty, which was selected from among 30+ other pitches.",
      "Hired crew members to assist in producing the selected pilot episode.",
      "Generated deal memos/start paperwork for all hired editors, operators, and production assistants.",
      "Issued and distributed insurance certificates, actor deal memos, and contracts.",
      "Coordinated schedules, transportation, and location scouting for the production team.",
    ],
    url: "https://www.youtube.com/watch?v=AkIhYfjFXbM&t=1s",
    category: "Film/TV",
    videos: ["https://www.youtube.com/embed/AkIhYfjFXbM?si=RobSTNIDT6uwMLP1"],
    photos: [
      SavetheDateCover,
      StD1,
      StD2,
      StD3,
      StD4,
      StD5,
      StD6,
      StD7,
      StD8,
      StD9,
      StD10,
    ],
  },
  {
    name: "Humber Pastry Cup 2023",
    titleTop: "Humber Pastry",
    titleBottom: "Cup 2023",
    fontSizeTop: "5vw",
    fontSizeBottom: "7.964vw",
    date: new Date(2023, 3),
    location: "Etobicoke, ON",
    role: "Executive Producer",
    genre: "Cooking Show",
    organization: "Humber College",
    credits:
      "Produced by Shamanta Haider, directed by Saru Leal with TD Dante Bordonalli and edited by Anthony Penninoi and Julius David-Alleyne",
    description: [
      "An April 2023 collaboration with Humber College students from the Pastry and Baking Arts Management Program and 2nd year students from the Broadcast Television/ Videography Program. 10 amazing competitors,  and an amazing broadcast crew of 50 that spanned all roles of the production. The live broadcast was over 3 hours long, so this video is a 50 minute version, that consists entirely of the live footage, simply trimmed down for time. No other post-production trickery was used!",
      "The Pastry students were led by Chef Kenneth Ku and the Broadcast students were led by student Producer Shamanta Haider, Professors Craig Lapsley and John Johnson.",
    ],
    responsibilities: [
      "Developed the overall creative vision for the 2023 Humber College Pastry Cup.",
      "Collaborated with the Humber Pastry Arts Management Department to schedule and conduct interviews for the contestant show packages.",
      "Provided guidance to the production crew and on-camera talent during setup and live production.",
      "Supervised editors in the creation of the opening theme, show packages, and the final roll out videos.",
    ],
    url: "https://youtu.be/CSuZnm1ZeNs?si=F27V-mNTSPJGxz-h",
    category: "Film/TV",
    videos: ["https://www.youtube.com/embed/CSuZnm1ZeNs?si=4A0hdnBSl7CqzLM1"],
    photos: [HumberPastryCupCover],
  },
  {
    name: "Bonfire Originals",
    titleTop: "Bonfire",
    titleBottom: "Originals",
    fontSizeTop: "8vw",
    fontSizeBottom: "6.422vw",
    date: new Date(2023, 5, 2),
    location: "Toronto, ON",
    role: "Production Coordinator",
    genre: "Media Production",
    organization: "",
    credits: "",
    description: [],
    responsibilities: [
      "Effectively managed daily incoming and outgoing communications via email and phone.",
      "Coordinated all facets of daily production, from scheduling facilities and booking talent to fostering effective collaboration among production staff.",
      "Demonstrated meticulous financial acumen, proficiently creating and tracking budgets, quotes, and invoices, ensuring accurate reimbursement credits and smooth financial operations.",
      "Transformed interview transcripts into captivating scripts for 4 short documentary-style videos.",
    ],
    url: "https://www.bonfireoriginals.com/",
    category: "Film/TV",
    videos: ["https://www.youtube.com/embed/g5klG74TQnA?si=RS-LSTsRLUaHfBZq"],
    photos: [BonfireOriginalsCover],
  },
  {
    name: "CBC/Radio-Canada",
    titleTop: "CBC /",
    titleBottom: "Radio-Canada",
    fontSizeTop: "10vw",
    fontSizeBottom: "3.806vw",
    date: new Date(2023, 1, 3),
    location: "Toronto, ON",
    role: "Associate Director Intern",
    genre: "Broadcast Media Production and Distribution",
    organization: "",
    credits: "",
    description: [],
    responsibilities: [
      "Received training under various Assistant Directors (A.D.s) in productions such as The National, Local Toronto News, CBC Sports, CBC Kids, and The Fifth Estate, gaining insights into diverse aspects of broadcast television.",
    ],
    url: "https://cbc.radio-canada.ca/",
    category: "Film/TV",
    videos: [],
    photos: [CBCCover],
  },
  {
    name: "OCAA Vs. OUA Women’s Basketball",
    titleTop: "OCAA Vs. OUA",
    titleBottom: "Women's Basketball",
    fontSizeTop: "5vw",
    fontSizeBottom: "3.106vw",
    date: new Date(2022, 8, 29),
    location: "Etobicoke, ON",
    role: "Editor",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Waterloo University"],
    responsibilities: [],
    url: "https://www.youtube.com/@humberhawks",
    category: "Live Sports",
    videos: [],
    photos: [HawksSportsLogo],
  },
  {
    name: "OCAA Vs. OUA Men’s Basketball",
    titleTop: "OCAA Vs. OUA",
    titleBottom: "Men's Basketball",
    fontSizeTop: "5vw",
    fontSizeBottom: "3.658vw",
    date: new Date(2022, 8, 29),
    location: "Etobicoke, ON",
    role: "Camera Operator",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Waterloo University"],
    responsibilities: [],
    url: "https://www.youtube.com/@humberhawks",
    category: "Live Sports",
    videos: [],
    photos: [HawksSportsLogo],
  },
  {
    name: "OCAA Women’s Volleyball",
    titleTop: "OCAA Women's",
    titleBottom: "Volleyball",
    fontSizeTop: "4vw",
    fontSizeBottom: "5.968vw",
    date: new Date(2022, 10, 5),
    location: "Etobicoke, ON",
    role: "Director",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Cambrian College"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=TB2TVGnUhKo",
    category: "Live Sports",
    videos: [],
    photos: [OCAAWomensVolleyballHumberVsCambrian],
  },
  {
    name: "OCAA Men’s Volleyball",
    titleTop: "OCAA Men's",
    titleBottom: "Volleyball",
    fontSizeTop: "5.5vw",
    fontSizeBottom: "6.482vw",
    date: new Date(2022, 10, 5),
    location: "Etobicoke, ON",
    role: "Camera Operator",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Cambrian College"],
    responsibilities: [],
    url: "https://www.youtube.com/embed/GcbjiBjicPw?si=WktU85oshnGHwh_0",
    category: "Live Sports",
    videos: [],
    photos: [OCAAMensVolleyballHumberVsCambrian],
  },
  {
    name: "OCAA Women’s Volleyball",
    titleTop: "OCAA Women's",
    titleBottom: "Volleyball",
    fontSizeTop: "4vw",
    fontSizeBottom: "5.968vw",
    date: new Date(2022, 11, 1),
    location: "Etobicoke, ON",
    role: "Associate Graphics Producer",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Sheridan College"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=Z2ptoFUFuNQ",
    category: "Live Sports",
    videos: [],
    photos: [OCAAWomensVolleyballHumberVsSheridan],
  },
  {
    name: "Humber College E-Sports Smash Bros. Tournament",
    titleTop: "Humber College E-Sports",
    titleBottom: "Smash Bros. Tournament",
    fontSizeTop: "3vw",
    fontSizeBottom: "2.966vw",
    date: new Date(2022, 11, 8),
    location: "Etobicoke, ON",
    role: "Director",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: [
      "Directed a live broadcast of seven back-to-back Super Smash Bros. Ultimate games up to finals.",
    ],
    responsibilities: [],
    url: "https://www.instagram.com/p/CoVfEs8gCxG/?hl=en",
    category: "Live Sports",
    videos: [],
    photos: [HumberEsportsSmash],
  },
  {
    name: "OCAA Men’s Volleyball",
    titleTop: "OCAA Men's",
    titleBottom: "Volleyball",
    fontSizeTop: "5.5vw",
    fontSizeBottom: "6.482vw",
    date: new Date(2023, 0, 19),
    location: "Etobicoke, ON",
    role: "Camera Operator",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Fanshawe College"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=kOTCegWIbYI",
    category: "Live Sports",
    videos: [],
    photos: [Fanshawe2],
  },
  {
    name: "OCAA Women’s Volleyball",
    titleTop: "OCAA Women's",
    titleBottom: "Volleyball",
    fontSizeTop: "4.7vw",
    fontSizeBottom: "7.013vw",
    date: new Date(2023, 0, 19),
    location: "Etobicoke, ON",
    role: "Camera Operator",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Fanshawe College"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=zvkRaf2GARU",
    category: "Live Sports",
    videos: [],
    photos: [Fanshawe1],
  },
  {
    name: "OCAA Men’s Basketball",
    titleTop: "OCAA Men's",
    titleBottom: "Basketball",
    fontSizeTop: "5vw",
    fontSizeBottom: "5.459vw",
    date: new Date(2023, 1, 4),
    location: "Etobicoke, ON",
    role: "Director",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Canadore College"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=RUvstIyEmEc",
    category: "Live Sports",
    videos: [],
    photos: [OCAAMensBasketballHumberCollegeVsCanadoreCollege],
  },
  {
    name: "OCAA Women’s Volleyball",
    titleTop: "OCAA Women's",
    titleBottom: "Volleyball",
    fontSizeTop: "4vw",
    fontSizeBottom: "5.968vw",
    date: new Date(2023, 1, 9),
    location: "Etobicoke, ON",
    role: "Producer",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Redeemer University"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=yojenopLZdY",
    category: "Live Sports",
    videos: [],
    photos: [OCAAWomensVolleyballHumberVsRedeemer],
  },
  {
    name: "OCAA Men’s Volleyball",
    titleTop: "OCAA Men's",
    titleBottom: "Volleyball",
    fontSizeTop: "5.5vw",
    fontSizeBottom: "6.482vw",
    date: new Date(2023, 1, 9),
    location: "Etobicoke, ON",
    role: "Producer",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Redeemer University"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=9U1wvJ5uaiU",
    category: "Live Sports",
    videos: [],
    photos: [OCAAMensVolleyballHumberVsRedeemer],
  },
  {
    name: "OHL Men’s Hockey",
    titleTop: "OHL Men's",
    titleBottom: "Hockey",
    fontSizeTop: "5vw",
    fontSizeBottom: "6.899vw",
    date: new Date(2023, 1, 20),
    location: "Etobicoke, ON",
    role: "Director",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Mississauga Steelheads Vs. Saginaw Spirits"],
    responsibilities: [],
    url: "",
    category: "Live Sports",
    videos: [],
    photos: [OHLHockey],
  },
  {
    name: "Humber Music Producer-in-Residence",
    titleTop: "Humber Music",
    titleBottom: "Producer-in-Residence",
    fontSizeTop: "5vw",
    fontSizeBottom: "3.16vw",
    date: new Date(2023, 1, 22),
    location: "Etobicoke, ON",
    role: "Camera Operator",
    genre: "Media Production",
    organization: "Humber College",
    credits: "",
    description: [
      "Live broadcast of Humber music students' capstone projects.",
    ],
    responsibilities: [],
    url: "",
    category: "Film/TV",
    videos: [],
    photos: [HumberMusicProducerinResidence],
  },
  {
    name: "CCAA Men’s Volleyball Nationals",
    titleTop: "CCAA Men's Volleyball",
    titleBottom: "Nationals",
    fontSizeTop: "3.2vw",
    fontSizeBottom: "7.462vw",
    date: new Date(2023, 2, 9),
    location: "Etobicoke, ON",
    role: "Associate Graphics Producer",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Briercrest College and Seminary Vs. Providence College"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=-1LX1PPLsv0",
    category: "Live Sports",
    videos: [],
    photos: [BriercrestVsProvidence],
  },
  {
    name: "CCAA Men’s Volleyball Nationals",
    titleTop: "CCAA Men's Volleyball",
    titleBottom: "Nationals",
    fontSizeTop: "3.2vw",
    fontSizeBottom: "7.462vw",
    date: new Date(2023, 2, 9),
    location: "Etobicoke, ON",
    role: "Associate Graphics Producer",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Vancouver Island University Vs. Université Sainte-Anne"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=2gWp3UvzErQ",
    category: "Live Sports",
    videos: [],
    photos: [VIUVsSaintAnne],
  },
  {
    name: "CCAA Men’s Volleyball Nationals",
    titleTop: "CCAA Men's Volleyball",
    titleBottom: "Nationals",
    fontSizeTop: "3.2vw",
    fontSizeBottom: "7.462vw",
    date: new Date(2023, 2, 9),
    location: "Etobicoke, ON",
    role: "Producer",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Camosun College Vs. Fanshawe College"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=eDs8AV4dWvo&list=PLnDoUemuBBiAOqYb240PDeqQaedc_Z4H8&index=7",
    category: "Live Sports",
    videos: [],
    photos: [CamosunVsFanshawe],
  },
  {
    name: "CCAA Men’s Volleyball Nationals",
    titleTop: "CCAA Men's Volleyball",
    titleBottom: "Nationals",
    fontSizeTop: "3.2vw",
    fontSizeBottom: "7.462vw",
    date: new Date(2023, 2, 9),
    location: "Etobicoke, ON",
    role: "Producer",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: ["Humber College Vs. Université de Sherbrooke"],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=V-zl4pe7-_0&list=PLnDoUemuBBiAOqYb240PDeqQaedc_Z4H8&index=6",
    category: "Live Sports",
    videos: [],
    photos: [Sherbrooke],
  },
  {
    name: "CCAA Men’s Volleyball Nationals",
    titleTop: "CCAA Men's Volleyball",
    titleBottom: "Nationals",
    fontSizeTop: "3.2vw",
    fontSizeBottom: "7.462vw",
    date: new Date(2023, 2, 11),
    location: "Etobicoke, ON",
    role: "Director",
    genre: "Live Sports",
    organization: "Humber College",
    credits: "",
    description: [
      "Vancouver Island University Vs. Briercrest College and Seminary",
    ],
    responsibilities: [],
    url: "https://www.youtube.com/watch?v=dHlLrukOxks&list=PLnDoUemuBBiAOqYb240PDeqQaedc_Z4H8&index=2",
    category: "Live Sports",
    videos: [],
    photos: [VIUVsBriercrest],
  },
  {
    name: "Broadcast Services International",
    titleTop: "Broadcast Services",
    titleBottom: "International",
    fontSizeTop: "4vw",
    fontSizeBottom: "6.049vw",
    date: new Date(2023, 10, 15),
    location: "Burlington, ON",
    role: "Marketing and Sales Coordinator",
    genre: "Media Production",
    organization: "",
    credits: "",
    description: [
      "General Support for the Marketing and Sales Team including the managements of sales databases.",
      "Co-ordinate marketing and sales meetings, appointments, reservations, and travel arrangements.",
      "Plan, organize and co-ordinate paperwork and perform research for the bids, tenders, and special projects.",
      "Assist with the preparation of business presentations including text, and electronic presentations.",
      "Execute marketing strategies relating to sales and assist with writing of articles, social media, and other sales initiatives.",
    ],
    responsibilities: [],
    url: "https://bsi-tv.com/",
    category: "PR & Marketing",
    videos: [],
    photos: [BSICover],
  },
  {
    name: "NATA PR",
    titleTop: "NATA",
    titleBottom: "PR",
    fontSizeTop: "5vw",
    fontSizeBottom: "9.69vw",
    date: new Date(2022, 7, 26),
    location: "Toronto, ON",
    role: "Communications Assistant",
    genre: "Public Relations and Communications Services",
    organization: "",
    credits: "",
    description: [
      "Drafted monthly press releases and social media content for bands like Proderm, Dr. Renaud and Bleu Lavande.",
      "Wrote mass pitches and processed sample requests for diverse lifestyle brands like Moscot, Lambert and Yoga Jeans.",
      "Created campaign reports monthly using the MRP media database.",
      "Contacted influencers monthly for several affiliate programs and media campaigns.",
      "Assisted with media events by managing invitations, arranging catering, sourcing decor, and more.",
    ],
    responsibilities: [],
    url: "https://natapr.com",
    category: "PR & Marketing",
    videos: [],
    photos: [NataPRCover],
  },
  {
    name: "accessCAN",
    titleTop: "access",
    titleBottom: "CAN",
    fontSizeTop: "5vw",
    fontSizeBottom: "9.958vw",
    date: new Date(2021, 6, 17),
    location: "Toronto, ON",
    role: "Marketing & Communications Coordinator",
    genre: "Busineness Consulting and Services",
    organization: "",
    credits: "",
    description: [
      "Provided marketing communications expertise in the areas of web strategy, content creation and brand management.",
      "Redesigned the company website within the first 3 weeks using applications like Squarespace, Hubspot, and AccessiBe.",
      "Planned, researched, and wrote 6 original blog posts for the company website in a month.",
      "Tracked and measured content marketing performance which increased by almost 20% after redesigning and launching the new website.",
    ],
    responsibilities: [],
    url: "https://www.linkedin.com/company/accesscan/about/",
    category: "PR & Marketing",
    videos: [],
    photos: [accessCANCover],
  },
  {
    name: "the Academy of Canadian Cinema and Television",
    titleTop: "the Academy of Canadian",
    titleBottom: "Cinema and Television",
    fontSizeTop: "2.7vw",
    fontSizeBottom: "3.06vw",
    date: new Date(2024, 6, 17),
    location: "Toronto, ON",
    role: "Multimedia Marketing Producer",
    genre: "Film and Television",
    organization: "the Academy of Canadian Cinema and Television",
    credits: "",
    description: [
      "As a Multimedia Marketing Producer at the Canadian Academy of Cinema and Television, I am responsible for curating and editing engaging content across our social media platforms, including Instagram, TikTok, and YouTube, where we have amassed a following of 41K+ dedicated fans. In addition to content creation, I play a pivotal role in crafting captivating social copy and designing visually appealing graphics to enhance our online presence. I am also tasked with developing promotional materials such as eBlasts, and updating web pages to support various campaigns. Beyond digital endeavors, I will be providing on-site and social media support for the 2024 Canadian Screen Week events held annually in May. Furthermore, I take the lead in outreach efforts for our highly anticipated Audience Choice Award and Kids’ Choice Award promotions, driving engagement and excitement among our audience. Through my role, I contribute to celebrating and promoting the outstanding achievements of Canadian cinema and television."
    ],
    responsibilities: [],
    url: "https://www.academy.ca/",
    category: "PR & Marketing",
    videos: [],
    photos: [CSACover],
  },
];
